<template lang="pug">
div
  b-modal(ref="chart_remove_modal" modal-class="own_modal chart_remove_modal" :no-auto-focus="true"
          :no-enforce-focus="true")
    template(slot="modal-header")
      div.content
        div.inner.pb-3
          div.title {{ confirm_title }}
    div.confirm_message
      icon(name="trash")
      span.pl-3 {{ confirm_message }}
    template(slot="modal-footer")
      b-button-group
        b-button.left_radius(@click="hide()" variant="success" size="lg" class="dismiss") Cancel
        b-button.submit.right_radius(@click="confirm()" variant="warning" size="lg")
          | {{ confirm_action }}
</template>

<script>
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/trash'
import bus from '@/services/bus'

const default_options = {
  confirm_title: 'Please confirm', confirm_message: 'Are you sure ?', confirm_action: 'Ok'}

export default {
  name: 'User_confirm_modal',
  components: {
    Icon
  },
  data () {
    return {
      confirm_title: null,
      confirm_message: null,
      confirm_action: null,
      modal: null
    }
  },

  mounted () {
    this.modal = this.$refs['chart_remove_modal']
    for (let prop of Object.keys(default_options)) this[prop] = default_options[prop]
  },

  methods: {
    show: function (identifier, options) {
      this.identifier = identifier
      if (options !== null && typeof options === 'object') {
        for (let prop of Object.keys(options)) this[prop] = options[prop]
      }
      this.modal.show()
    },

    hide: function (confirm) {
      bus.$emit(`user_confirm/${this.identifier}`, confirm)
      for (let prop of Object.keys(default_options)) this[prop] = default_options[prop]
      this.identifier = null
      this.modal.hide()
    },

    confirm: function () {
      this.hide(true)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';
.own_modal {
  .content {
    background-color: $red;
    .title {
      color: $white !important;
    }
  }
  .confirm_message {
    background-color: $red;
    color: $white;
    font-size: 1.3rem;
    margin: 0;
    padding: 1.1rem 0;
    // padding-bottom: 15px;
    // padding-top: 15px;
    text-align: center;
    * {
      vertical-align: middle;
    }
    .fa-icon {
      height: 2rem;
      width: auto;
    }
  }
}
</style>

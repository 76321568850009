<template lang="pug">
div
  b-modal(ref="data_list_modal_ref" modal-class="own_modal data_list_modal" :no-auto-focus="true" 
         :no-enforce-focus="true")
    //- HEADER
    template(slot="modal-header")
      div.content
        div.inner.pb-3
          div.title {{ title }}
        div.spinner
          clip-loader(:loading="loading" size="28px")
    //- CONTENT
    div.row.filter_options
      div.col.filter_form.host
        b-form-select(v-model="host_filter" :options="host_filter_form_options")
        icon.form-select(name="sort-down")
      div.col.filter_form.type(v-show="show_internal_type")
        b-form-select(v-model="type_filter" :options="type_filter_form_options")
        icon.form-select(name="sort-down")
      div.col.search_widget
        //- div.icon(@click="search_expanded=!search_expanded")
        icon(name="search")
        b-form-input(v-model="search_input" type="text" placeholder="Search")
    b-table.data_list#pager-aria(small hover :items="filtered_data_map" :fields="data_fields"
                      :filter="search_input" :per-page="per_page" :current-page="current_page")
      template(v-slot:cell(name)="data")
        div.data_name(@click="on_data_clicked(data.item.id)"
                      v-bind:style="{'background-color': data.item.color}")
          | {{ data.item.shortname }}
      template(v-slot:cell(host_name)="data")
        div.right(@click="on_data_clicked(data.item.id)"
                  v-bind:style="{'background-color': data.item.color}")
          | {{ data.item.host_name }}
    b-pagination(v-model="current_page" v-on:change="on_page_changed" size="lg" align="center"
                 :total-rows="filtered_data_map.length" :per-page="per_page"
                 aria-controls="pager-aria")
    //- FOOTER
    template(slot="modal-footer")
      b-button-group
        b-button.left_radius.right_radius(@click="hide()" variant="warning" size="lg") Cancel
</template>

<script>
import bus from '@/services/bus'
import Icon from 'vue-awesome/components/Icon'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { mapGetters } from 'vuex'
import { get_element_by_id, get_index_by_id } from '@/utils'
// import CONF from '@/conf'

import 'vue-awesome/icons/sort-down'
import 'vue-awesome/icons/search'

var host_filter_form_default = [
  { text: 'Host', value: null }
]

const default_title = 'Data selection'
const default_options = { show_internal_type: false, title: default_title, exclude_map: [] }

export default {
  name: 'Data_modal',
  components: {
    'icon': Icon,
    'clip-loader': ClipLoader
  },

  data () {
    return {
      loading: true,
      show_internal_type: true,
      selection: false,
      // search_expanded: false,
      search_input: null,
      filtered_data_map: [],
      per_page: 10,
      current_page: 1,
      host_filter: null,
      type_filter: null,
      host_filter_form_options: host_filter_form_default,
      type_filter_form_options: [
        { text: 'Type', value: null },
        { text: 'Alarm', value: 'AL' },
        { text: 'Net status', value: 'NS' }
      ],
      data_fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'host_name', label: 'Host', sortable: true }
      ],
      exclude_map: [],
      title: default_title
    }
  },

  computed: {
    ...mapGetters({
      data_map: 'get_data_map',
      host_map: 'get_host_map',
      data_map_state: 'get_data_map_state'
    })
  },

  watch: {
    host_filter: function (unused_newvalue, unused_oldvalue) {
      this.filtered_data_map = []
      for (let data of this.data_map) this.add_data(data)
    },
    type_filter: function (unused_newvalue, unused_oldvalue) {
      this.filtered_data_map = []
      for (let data of this.data_map) this.add_data(data)
    }
  },

  created () {
  },

  mounted () {
    for (let prop of Object.keys(default_options)) this[prop] = default_options[prop]
    this.modal = this.$refs['data_list_modal_ref']
  },

  beforeDestroy: function () {
  },

  methods: {
    show: function (identifier, options) {
      this.identifier = identifier
      if (options !== null && typeof options === 'object') {
        for (let prop of Object.keys(options)) this[prop] = options[prop]
      }
      if (this.data_map_state) {
        for (let data of this.data_map) this.add_data(data)
        this.loading = false
      } else {
        let already_fetched = ''
        if (this.host_landing_state) {
          already_fetched += 'host,'
          this.set_host_options()
        }
        this.$socket.send('data_map', JSON.stringify({already_fetched: already_fetched}))
      }
      bus.$on('state/data_map', (unused_response) => {
        this.loading = false
        this.set_host_options()
      })
      bus.$on('update/data', (response) => {
        let data = get_element_by_id(this.data_map, response.id)
        if (data == null) return
        if (get_index_by_id(this.filtered_data_map, response.id) !== -1) return
        this.add_data(data)
      })
      this.modal.show()
    },

    hide: function (data_pk) {
      if (data_pk) bus.$emit(`data_selection/${this.identifier}`, data_pk)
      bus.$off('state/data_map')
      bus.$off('update/data')
      for (let prop of Object.keys(default_options)) this[prop] = default_options[prop]
      this.search_input = null
      this.filtered_data_map = []
      this.current_page = 1
      this.host_filter = null
      this.type_filter = null
      this.modal.hide()
    },

    on_page_changed: function (unused_page) {
      // window.scrollTo(0, 0)
    },

    on_data_clicked: function (data_pk) { this.hide(data_pk) },

    add_data: function (data) {
      if (this.exclude_map.indexOf(data.id) > -1) return
      if (!data.visible || !data.enabled) return
      if (!this.show_internal_type && data.role !== 'NO') return
      if (!this.type_filter && data.role !== 'NO') return
      if (this.type_filter && data.role !== this.type_filter) return
      let host = get_element_by_id(this.host_map, data.host)
      data.color = host ? host.color : '#000'
      data.host_name = host ? host.name : 'Unknown'
      if (this.host_filter && host) {
        if (host.name !== this.host_filter) return
      }
      this.filtered_data_map.push(data)
    },

    set_host_options: function () {
      this.host_filter_form_options = host_filter_form_default
      for (let host of this.host_map) {
        this.host_filter_form_options.push({ text: host.name, value: host.name })
      }
    }
  },
}
</script>

<style lang="scss">
@import '../assets/colors';
@import '../assets/mixins';

.data_list_modal {
  .modal-header .inner .title {
    color: $black;
  }
  .v-spinner {
    .v-clip {
      border-color: lighten($black, 20) lighten($black, 30)  transparent !important;
    }
  }
  .filter_options {
    margin: 0;
    padding: 0;
    .col {
      margin: 0;
      padding: 0;
      position: relative;
    }
    select {
      background-color: transparent !important;
      border: 0 !important;
      font-size: inherit;
      height: 100% !important;
    }
    .fa-icon.form-select {
      color: lighten($black, 40);
      height: 16px;
      right: .5rem;
      top: .3rem;
    }
    .search_widget,
    .filter_form {
      background-color: darken($white, 15) !important;
      border: 1px solid lighten($black_shadow_light, 20);
      border-collapse: collapse;
      border-radius: 0;
      border-right: 0;
      color: lighten($black, 20);
      font-size: 1.25rem;
      margin: 0;
      .host {
        border-left: 0;
      }
    }
    .search_widget {
      display: flex;
      float: right;
      svg {
        color: lighten($black, 40);
        height: 100%;
        margin: 0 .4rem;
        width: 15px;
      }
      input {
        background-color: transparent !important;
        border: 0 !important;
        border-radius: 0 !important;
        font-size: inherit;
        padding: 0;
      }
    }
  }

  table {
    &.data_list {
      thead {
        color: $black;
        tr {
          th {
            background-color: darken($white, 20);
            border: 0;
            font-size: 1.1rem;
            padding-bottom: .6rem;
            padding-top: .8rem;
            &:last-child {
              text-align: right;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid transparent;
          color: $white;
          font-size: 1.4rem;
          td {
            border-top: 0;
            cursor: pointer;
            height: 40px;
            padding: 0;
            .data_name {
              overflow: hidden;
            }
            .right {
              text-align: right;
            }
            > div {
              height: 100%;
              padding: 1.2rem .5rem;
            }
          }
        }
      }
    }
  }
}

</style>

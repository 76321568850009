<template lang="pug">
div
  b-modal(ref="set_order_modal" modal-class="own_modal" header-class="own_header" 
          :no-auto-focus="true" :no-enforce-focus="true")
    //- header
    template(slot="modal-header")
      div.content
        div.inner.pb-3
          div.title Set {{ main_prop }} positions
        div.spinner
          clip-loader(:loading="loading" size="30px")
    //- content
    div.item(v-for="element in element_layout" :key="element.id"
                     v-bind:style="{'background-color': element.color}")
      span.name {{ element.name }}
      div.buttons
        b-button.top(variant="success" size="lg" @click="move(element, 'top')"
                     v-bind:class="get_button_class(element, 'up')")
          icon(name="arrow-up")
        b-button.bottom(variant="success" size="lg" @click="move(element, 'bottom')"
                        v-bind:class="get_button_class(element, 'down')")
          icon(name="arrow-down")
        b-button(variant="success" size="lg"  @click="move(element, 'up')"
                 v-bind:class="get_button_class(element, 'up')")
          icon(name="arrow-up")
        b-button(variant="success" size="lg" @click="move(element, 'down')"
                 v-bind:class="get_button_class(element, 'down')")
          icon(name="arrow-down")
    //- footer
    template(slot="modal-footer")
      b-button-group
        b-button.dismiss.left_radius(@click="hide()"
                 :variant="!submit_enabled ? 'success' : 'danger'" size="lg") Cancel
        b-button.submit.right_radius(@click="submit()" size="lg"
                        :variant="submit_enabled ? 'success' : 'warning' "
                        v-bind:class="submit_enabled ? '' : 'disabled' ") Apply
</template>

<script>
import bus from '@/services/bus'
import { mapGetters } from 'vuex'
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/arrow-up'
import 'vue-awesome/icons/arrow-down'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import CONF from '@/conf'
import { get_element_by_id, get_index_by_id } from '@/utils'
var orderBy = require('lodash/orderBy')

export default {
  name: 'Set_order_modal',
  components: {
    'icon': Icon,
    ClipLoader
  },
  props: {
    'main_prop': {type: String, required: true},
  },
  data () {
    return {
      modal: null,
      element_map: null,
      element_layout: [],
      loading: false,
      submit_enabled: false
    }
  },

  computed: {
    ...mapGetters({
      control_map: 'get_control_map',
      chart_map: 'get_chart_map',
      host_map: 'get_host_map',
      data_map: 'get_data_map'
    })
  },

  mounted () {
    this.modal = this.$refs['set_order_modal']
    bus.$on(`show_${this.main_prop}_order_modal`, () => { this.show() })
  },

  beforeDestroy () {
    bus.$off(`show_${this.main_prop}_order_modal`)
  },

  methods: {
    show () {
      this.element_map = this[`${this.main_prop}_map`]
      for (let element of orderBy(this.element_map, 'position')) {
        let main_data = get_element_by_id(this.data_map, element.data)
        let host = get_element_by_id(this.host_map, main_data.host)
        this.element_layout.push({
          id: element.id, name: main_data.name, color: host.color })
      }
      this.modal.show()
    },

    hide () {
      this.loading = false
      this.submit_enabled = false
      this.element_layout = []
      this.modal.hide()
    },

    move (item, position) {
      let index = get_index_by_id(this.element_layout, item.id)
      if (index < 0) return
      let direction = ((position === 'bottom') || (position === 'down')) ? 'down' : 'up'
      if (!index && (direction === 'up')) return
      if ((index === this.element_layout.length - 1) && (position === 'down')) return
      this.element_layout.splice(index, 1)
      if (position === 'top') this.element_layout.splice(0, 0, item)
      if (position === 'bottom') this.element_layout.push(item)
      if (position === 'up') this.element_layout.splice(index - 1, 0, item)
      if (position === 'down') this.element_layout.splice(index + 1, 0, item)
      this.submit_enabled = true
    },

    get_button_class (item, button_type) {
      let index = get_index_by_id(this.element_layout, item.id)
      if (index < 0) return 'disabled'
      if (index && (button_type === 'up')) return
      if ((index < this.element_layout.length - 1) && (button_type === 'down')) return
      return 'disabled'
    },

    submit () {
      let submited_layout = []
      let counter = 0
      console.log(this.element_layout.length)
      for (let element of this.element_layout) {
        submited_layout.push({ id: element.id, position: counter })
        counter++
      }
      this.loading = true
      let url = CONF[`${this.main_prop.toUpperCase()}_POSITIONS_URL`]
      let submit = this.$http.post(
        url, { positions: JSON.stringify(submited_layout) }, CONF.REQUEST_OPTIONS)
      this.hide()
      submit.then(
        unused_response => {
          this.$store.commit('set_positions', {
            attribute: this.main_prop,
            position_map: submited_layout
          })
        },
        // ERROR
        response => {
          console.error(`Error POST request: ${response.status} ${response.body.text}`)
          this.$toaster['error'](response.body.text)
        }
      )
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';

.own_header {
  .title {
    color: $black;
  }
}

h6 {
  margin-bottom: 0;
}

.item {
  align-items: center;
  color: $white;
  display: table;
  font-size: 1.5em;
  padding: .2em .5em;
  vertical-align: middle;
  width: 100%;

  .buttons {
    display: table-cell;
    text-align: right;
    button {
      background-color: transparent;
      border: 0;
      opacity: .8;
      &.top {
        svg {
          border-top: 3px solid;
        }
      }
      &.bottom {
        svg {
          border-bottom: 3px solid;
        }
      }
      &:hover {
        background-color: $white_shadow_light2;
        opacity: 1;
      }
      &.disabled {
        opacity: .3;
      }
    }
  }
}
</style>
